import { Route, Routes } from "react-router-dom";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/RequireAuth";
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import MyPage from "./pages/mypage/MyPage";
// import Survey from "./pages/Survey";
import Recommend from "./pages/Recommend";
import CheckupSurvey from "./pages/surveys/checkup/Checkup";
import CheckupReport from "./pages/surveys/checkup/CheckupReport";
import Dashboard from "./pages/dashboard/Dashboard";
import ITree from "./pages/itree/ITree";
import Smile from "./pages/ai-smile/desktop/Smile";
import Popup from "./pages/ai-smile/desktop/Popup";
import Password from "./pages/mypage/Password";
import FindPassword from "./pages/auth/FindPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import MaumScoreDetail from "./pages/dashboard/MaumScoreDetail";
import Prototype from "./pages/block/Prototype";
import CheckupResult from "./pages/surveys/checkup/CheckupResult";
import Analyzing from "./pages/ai-smile/desktop/Analyze";
import AIResult from "./pages/ai-smile/desktop/AIResult";
import Guide from "./pages/Guide";
import HomeLayout from "./components/layouts/HomeLayout";
import GuideLayout from "./components/layouts/GuideLayout";
import Announcement from "./pages/Announcement";
import Faq from "./pages/Faq";
import RouteChangeTracker from "./components/RouteChangeTracker";
import ItreeResult from "./pages/itree/ItreeResult";
import StepOne from "./pages/itree/StepOne";
import StepTwo from "./pages/itree/StepTwo";
import { useMediaQuery } from "react-responsive";
import SmileMobile from "./pages/ai-smile/mobile/SmileMobile";
import AiSmileMobileLayout from "./pages/ai-smile/mobile/AiSmileMobileLayout";
import PopupMobile from "./pages/ai-smile/mobile/PopupMobile";
import AnalyzeMobile from "./pages/ai-smile/mobile/AnalyzeMobile";
import AiResultMobile from "./pages/ai-smile/mobile/AiResultMobile";
import AIChampions from "./pages/ai-smile/mobile/AIChampions";
import Champions from "./pages/ai-smile/desktop/Champions";
import Training from "./pages/block/Training";
import Main from "./pages/surveys/checkup@2.0/Main";
import Survey from "./pages/surveys/checkup@2.0/Survey";
import Result from "./pages/surveys/checkup@2.0/Result";
import Share from "./pages/surveys/checkup@2.0/Share";
import ShareList from "./pages/surveys/checkup@2.0/ShareList";
import SmartTrainingReport from "./pages/smart-training-set/SmartTrainingReport";
import PersonalReport from "./pages/surveys/checkup@2.0/PersonalReport";
import SmartTrainingLeaderReport from "./pages/smart-training-set/SmartTrainingLeaderReport";
import ReactPdf from "./pages/smart-training-set/ReactPdf";
import StepThree from "./pages/itree/StepThree";
import WeekReport from "./pages/surveys/checkup@2.0/WeekReport";
import LeaderReport from "./pages/surveys/checkup@2.0/LeaderReport";
import { TYPE_LEADER } from "./Constants";
import AdminReport from "./pages/surveys/checkup@2.0/AdminReport";
import Layout from "./components/layouts/Layout";
import DashboardLayout from "./components/layouts/DashboardLayout";
import PersonalDashboard from "./pages/dashboard/User/PersonalDashboard";
import LeaderDashboard from "./pages/dashboard/Leader/LeaderDashboard";
import DashboardCheckupReport from "./pages/dashboard/Leader/CheckupReport";
import {
  SIDEBAR_ITEMS_LEADER,
  SIDEBAR_ITEMS_USER,
  SIDEBAR_ITEMS_MANAGER,
} from "./constants/sidebar";
import PersonalCheckup from "./pages/dashboard/User/PersonalCheckup";
import PersonalTraining from "./pages/dashboard/User/PersonalTraining";
import PersonalMypage from "./pages/dashboard/User/PersonalMypage";
import DashboardCheckupReportMonth from "./pages/dashboard/Leader/CheckupReportMonth";
import DashboardCheckupReportWeek from "./pages/dashboard/Leader/CheckupReportWeek";
import UsersManagement from "./pages/dashboard/Leader/UsersMangement";
import PersonalReward from "./pages/dashboard/User/PersonalReward";
import WithdrawManagement from "./pages/dashboard/Leader/WithdrawManagement";
import SmartTrainingBlock from "./pages/block/SmartTrainingBlock";
import Confetti from "./pages/block/components/Confetti";
import CheckupShareAnswerPage from "./pages/dashboard/CheckupShareAnswerPage";
import CheckupShareListPage from "./pages/dashboard/CheckupShareListPage";
import PersonalCheckupReportMonth from "./pages/dashboard/User/PersonalCheckupReportMonth";
import PersonalCheckupReportWeek from "./pages/dashboard/User/PersonalCheckupReportWeek";
import Remind from "./pages/surveys/checkup@2.0/Remind";
import GuideLink from "./pages/guide/GuideLink";
import SubscribePayment from "./pages/payment/SubscribePayment";
import SubscribePaymentSuccess from "./pages/payment/SubscribePaymentSuccess";
import SetPassword from "./pages/auth/SetPassword";
import SignupLayout from "./components/layouts/SignupLayout";
import SetProfile from "./pages/auth/SetProfile";
import Signup from "./pages/auth/Signup";
import SignupVerify from "./pages/auth/SignupVerify";
import GroupsManagement from "./pages/dashboard/Leader/GroupsManagement";
import FirstSubscribeTracker from "./components/FirstSubscribeTracker";
import EnterEmail from "./pages/auth/EnterEmail";
import PersonalGuide from "./pages/dashboard/User/PersonalGuide";
import SetAdminProfile from "./pages/auth/SetAdminProfile";
import AdminSignupSuccess from "./pages/auth/AdminSignupSuccess";
import ManagerDashboardLayout from "./components/layouts/ManagerDashboardLayout";
import ManagerHome from "./pages/dashboard/manager/ManagerHome";
import ManagerPaymentManagement from "./pages/dashboard/manager/ManagerPaymentManagement";
import ManagerPaymentHistory from "./pages/dashboard/manager/ManagerPaymentHistory";
import ManagerGroup from "./pages/dashboard/manager/ManagerGroup";
import ManagerUser from "./pages/dashboard/manager/ManagerUser";
import Welcome from "./pages/auth/Welcome";
import Onboarding from "./pages/auth/Onboarding";
import Tutorial from "./pages/auth/Tutorial";
import SubscribePaymentUpdate from "./pages/payment/SubscribePaymentUpdate";
import NewBlock from "./pages/block/NewBlock";
import NewBlock14 from "./pages/block/NewBlock14";
import SetPhoneNumber from "./pages/auth/SetPhoneNumber";
import SetAgree from "./pages/auth/SetAgree";
import ResultForSale from "./pages/surveys/checkup@2.0/ResultForSale";
import SurveyForSale from "./pages/surveys/checkup@2.0/SurveyForSale";
import ManagerUsageResult from "./pages/dashboard/manager/ManagerUsageResult";
import ManagerProject from "./pages/dashboard/manager/ManagerProject";
import ManagerCheckupReport from "./pages/dashboard/manager/ManagerCheckupReport";
import ManagerMonthReport from "./pages/dashboard/manager/ManagerMonthReport";
import MoreTraining from "./pages/block/MoreTraining";
import HomeMobile from "./pages/HomeMobile";
import MobileLayout from "./components/layouts/MobileLayout";
import WrokshopTool from "./pages/guide/WrokshopTool";
import NewBlock45 from "./pages/block/NewBlock45";
import NewBlock46 from "./pages/block/NewBlock46";
import MainForSale from "./pages/surveys/checkup@2.0/MainForSale";
import RemindForSale from "./pages/surveys/checkup@2.0/RemindForSale";
import MyPageTraining from "./pages/MypageTraining";
import MyPageMobile from "./pages/MyPageMobile";
import MyPageTrainingAll from "./pages/MypageTrainingAll";
import MyPageCheckup from "./pages/MypageCheckup";
import MyPageCheckupAll from "./pages/MypageCheckupAll";
import NewBlock48 from "./pages/block/NewBlock48";
import NewBlock49 from "./pages/block/NewBlock49";
import MypageNotification from "./pages/MypageNotification";
import NewBlock44 from "./pages/block/NewBlock44";
import MypageProfile from "./pages/MypageProfile";
import MypageReward from "./pages/MypageReward";
import MypageWithdraw from "./pages/MypageWithdraw";
import MypageWithdrawConfirm from "./pages/MypageWithdrawConfirm";
import MypageBadge from "./pages/MypageBadge";
import Mission from "./pages/mission/Mission";
import MissionAnswerPage from "./pages/dashboard/MissionAnswerPage";
import EventPage from "./pages/event/EventPage";
import NewBlock54 from "./pages/block/NewBlock54";

function App() {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  RouteChangeTracker();
  // FirstSubscribeTracker();

  return (
    <Routes>
      <Route element={<PersistLogin />}>
        {isMobile ? (
          <Route element={<MobileLayout />}>
            <Route path="/" element={<HomeMobile />} />
            <Route path="/mypage" element={<MyPageMobile />} />
            <Route path="/mypage/badge" element={<MypageBadge />} />
            <Route path="/mypage/checkup" element={<MyPageCheckup />} />
            <Route path="/mypage/checkup/week" element={<MyPageCheckupAll />} />
            <Route
              path="/mypage/checkup/month"
              element={<MyPageCheckupAll />}
            />
            <Route
              path="/mypage/notification"
              element={<MypageNotification />}
            />
            <Route path="/mypage/profile" element={<MypageProfile />} />
            <Route path="/mypage/reward" element={<MypageReward />} />
            <Route
              path="/mypage/reward/withdraw"
              element={<MypageWithdraw />}
            />
            <Route
              path="/mypage/reward/withdraw/confirm"
              element={<MypageWithdrawConfirm />}
            />
            <Route path="/mypage/training" element={<MyPageTraining />} />
            <Route
              path="/mypage/training/all"
              element={<MyPageTrainingAll />}
            />
          </Route>
        ) : (
          <Route element={<HomeLayout />}>
            <Route path="/" element={<Home />} />
          </Route>
        )}
        <Route path="/event" element={<EventPage />} />
        <Route path="/login" element={<Login />} />
        <Route element={<SignupLayout />}>
          <Route path="/signup-enter" element={<EnterEmail />} />
          <Route path="/signup-verify" element={<EnterEmail />} />
          <Route path="/set-admin-profile" element={<SetAdminProfile />} />
          <Route path="/signup-success" element={<AdminSignupSuccess />} />
        </Route>
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route element={<SignupLayout />}>
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/set-agree" element={<SetAgree />} />
          <Route path="/set-phone" element={<SetPhoneNumber />} />
        </Route>

        <Route path="/find-password" element={<FindPassword />} />
        {/* <Route path="/survey" element={<Survey />} /> */}
        {/* <Route path="/survey/happiness" element={<Survey type="happiness" />} /> */}
        {/* <Route
          path="/survey/well-being"
          element={<Survey type="wellBeing" />}
        /> */}
        <Route path="/guidelink" element={<GuideLink />} />
        <Route path="/workshop" element={<WrokshopTool />} />
        <Route path="/itree" element={<ITree />} />
        <Route path="/itree/1" element={<StepOne />} />
        <Route path="/itree/2" element={<StepTwo />} />
        <Route path="/itree/3" element={<StepThree />} />
        <Route path="/itree/result" element={<ItreeResult />} />
        <Route path="/training/:id" element={<Training />} />
        <Route path="/smart-training/:id" element={<SmartTrainingBlock />} />
        <Route path="/confetti" element={<Confetti />} />
        {/* <Route element={isMobile && <AiSmileMobileLayout />} > */}
        <Route
          path="/smile-challenge"
          element={
            isMobile ? (
              <AiSmileMobileLayout>
                <SmileMobile />
              </AiSmileMobileLayout>
            ) : (
              <Smile />
            )
          }
        />
        <Route
          path="/smile-challenge/:cid"
          element={
            isMobile ? (
              <AiSmileMobileLayout>
                <SmileMobile />
              </AiSmileMobileLayout>
            ) : (
              <Smile />
            )
          }
        />
        {/* </Route> */}

        <Route
          path="/smile-challenge/popup"
          element={
            isMobile ? (
              <AiSmileMobileLayout>
                <PopupMobile />
              </AiSmileMobileLayout>
            ) : (
              <Popup />
            )
          }
        />
        <Route
          path="/smile-challenge/analyze"
          element={
            isMobile ? (
              <AiSmileMobileLayout>
                <AnalyzeMobile />
              </AiSmileMobileLayout>
            ) : (
              <Analyzing />
            )
          }
        />
        <Route
          path="/smile-challenge/result"
          element={
            isMobile ? (
              <AiSmileMobileLayout>
                <AiResultMobile />
              </AiSmileMobileLayout>
            ) : (
              <AIResult />
            )
          }
        />
        <Route
          path="/smile-challenge/champions"
          element={
            isMobile ? (
              <AiSmileMobileLayout>
                <AIChampions />
              </AiSmileMobileLayout>
            ) : (
              <Champions />
            )
          }
        />
        <Route
          path="/smile-challenge/champions/:cid"
          element={
            isMobile ? (
              <AiSmileMobileLayout>
                <AIChampions />
              </AiSmileMobileLayout>
            ) : (
              <Champions />
            )
          }
        />
        <Route
          path="/smart-training-set/report"
          element={<SmartTrainingReport />}
        />
        <Route
          path="/smart-training-set/leader-report"
          element={<SmartTrainingLeaderReport />}
        />
        <Route path="/smart-training-set/pdf" element={<ReactPdf />} />

        <Route element={<Layout />}>
          <Route path="/checkup/v2/demo" element={<Main />} />
          <Route path="/checkup/v2/demo/remind" element={<Remind />} />
          <Route path="/checkup/v2/demo/survey" element={<Survey />} />
          <Route path="/checkup/v2/demo/result" element={<Result />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/checkup/v2/test" element={<MainForSale />} />
          <Route path="/checkup/v2/test/remind" element={<RemindForSale />} />
          <Route path="/checkup/v2/test/survey" element={<SurveyForSale />} />
          <Route path="/checkup/v2/test/result" element={<ResultForSale />} />
        </Route>

        {/* 로그인 필요 */}
        <Route element={<RequireAuth />}>
          <Route element={<Layout />}>
            <Route path="/dashboard/score" element={<MaumScoreDetail />} />
            <Route path="/checkup" element={<CheckupSurvey />} />
            <Route path="/checkup/result" element={<CheckupResult />} />
            <Route path="/checkup/report" element={<CheckupReport />} />
            <Route path="/checkup/v2/" element={<Main />} />
            <Route path="/checkup/v2/remind" element={<Remind />} />
            {/* <Route path="/checkup/v2/survey" element={<Survey />} /> */}
            <Route path="/checkup/v2/weekly-report" element={<WeekReport />} />
            <Route path="/checkup/v2/result" element={<Result />} />
            {/* <Route path="/checkup/result" element={<CheckupResult />} />
            <Route path="/checkup/report" element={<CheckupReport />} /> */}
            <Route path="/checkup/v2/share" element={<ShareList />} />
            <Route path="/checkup/v2/share/:id" element={<Share />} />
            <Route
              path="/checkup/v2/report/week"
              element={<PersonalCheckupReportWeek />}
            />
            <Route path="/checkup/v2/report" element={<PersonalReport />} />
            <Route path="/payments/" element={<SubscribePayment />} />
            <Route
              path="/payments/success"
              element={<SubscribePaymentSuccess />}
            />

            <Route path="/payments/subscribe" element={<SubscribePayment />} />
            <Route
              path="/payments/subscribe/success"
              element={<SubscribePaymentSuccess />}
            />
            <Route
              path="/payments/subscribe/update"
              element={<SubscribePaymentUpdate />}
            />
            <Route path="/blocks/recommend" element={<MoreTraining />} />
          </Route>
          {/* <Route element={<DashboardLayout sticky={true} />}> */}
          <Route path="/checkup/v2/survey" element={<Survey />} />
          {/* </Route> */}
          <Route element={<SignupLayout />}>
            <Route path="/set-profile" element={<SetProfile />} />
            <Route path="/signup/success" element={<Welcome />} />
          </Route>
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/tutorial" element={<Tutorial />} />
          <Route element={<GuideLayout header="공지사항" />}>
            <Route path="/announcement" element={<Announcement />} />
          </Route>
          <Route element={<GuideLayout header="문의하기" />}>
            <Route path="/faq" element={<Faq />} />
          </Route>
          <Route element={<GuideLayout header="맘핏 센터 이용안내" />}>
            <Route path="/guide" element={<Guide />} />
          </Route>
          <Route element={<GuideLayout header="추천 코스" />}>
            <Route path="/recommend" element={<Recommend />} />
          </Route>

          <Route
            path="/ai-smile"
            element={
              isMobile ? (
                <AiSmileMobileLayout>
                  <SmileMobile />
                </AiSmileMobileLayout>
              ) : (
                <Smile />
              )
            }
          />

          <Route
            path="/ai-smile/popup"
            element={
              isMobile ? (
                <AiSmileMobileLayout>
                  <PopupMobile />
                </AiSmileMobileLayout>
              ) : (
                <Popup />
              )
            }
          />
          <Route
            path="/ai-smile/analyze"
            element={
              isMobile ? (
                <AiSmileMobileLayout>
                  <AnalyzeMobile />
                </AiSmileMobileLayout>
              ) : (
                <Analyzing />
              )
            }
          />
          <Route
            path="/ai-smile/result"
            element={
              isMobile ? (
                <AiSmileMobileLayout>
                  <AiResultMobile />
                </AiSmileMobileLayout>
              ) : (
                <AIResult />
              )
            }
          />
          <Route
            path="/ai-smile/champions"
            element={
              isMobile ? (
                <AiSmileMobileLayout>
                  <AIChampions />
                </AiSmileMobileLayout>
              ) : (
                <Champions />
              )
            }
          />
          <Route path="/password" element={<Password />} />
          <Route path="/blocks/7" element={<NewBlock />} />
          <Route path="/blocks/14" element={<NewBlock14 />} />
          <Route path="/blocks/44" element={<NewBlock44 />} />
          <Route path="/blocks/45" element={<NewBlock45 />} />
          <Route path="/blocks/46" element={<NewBlock46 />} />
          <Route path="/blocks/48" element={<NewBlock48 />} />
          <Route path="/blocks/49" element={<NewBlock49 />} />
          <Route path="/blocks/54" element={<NewBlock54 />} />
          <Route path="/blocks/:id" element={<Prototype />} />

          <Route path="/mission" element={<Mission />} />

          <Route element={<DashboardLayout items={SIDEBAR_ITEMS_USER} />}>
            <Route path="/dashboard" element={<PersonalDashboard />} />
            <Route
              path="/dashboard/checkup/report"
              element={<PersonalCheckup />}
            />
            <Route
              path="/dashboard/checkup/report/week"
              element={<PersonalCheckupReportWeek />}
            />
            <Route
              path="/dashboard/checkup/report/month"
              element={<PersonalCheckupReportMonth />}
            />
            <Route
              path="/dashboard/checkup/share"
              element={<CheckupShareListPage />}
            />
            <Route
              path="/dashboard/checkup/share/:id"
              element={<CheckupShareAnswerPage />}
            />
            <Route path="/dashboard/training" element={<PersonalTraining />} />

            <Route path="/dashboard/mypage" element={<PersonalMypage />} />
            <Route
              path="/dashboard/mypage/reward"
              element={<PersonalReward />}
            />
          </Route>
          {isMobile ? (
            <Route path="/dashboard/guide" element={<Tutorial />} />
          ) : (
            <Route element={<DashboardLayout items={SIDEBAR_ITEMS_USER} />}>
              <Route path="/dashboard/guide" element={<PersonalGuide />} />
            </Route>
          )}

          <Route
            element={<ManagerDashboardLayout items={SIDEBAR_ITEMS_MANAGER} />}
          >
            <Route path="/manager/dashboard" element={<ManagerHome />} />
            <Route
              path="/manager/dashboard/projects"
              element={<ManagerProject />}
            />
            <Route
              path="/manager/dashboard/users/groups"
              element={<ManagerGroup />}
            />
            <Route path="/manager/dashboard/users" element={<ManagerUser />} />
            <Route
              path="/manager/dashboard/mission/:id"
              element={<MissionAnswerPage />}
            />
            <Route
              path="/manager/dashboard/checkup/result"
              element={<ManagerUsageResult />}
            />
            <Route
              path="/manager/dashboard/checkup/report"
              element={<ManagerCheckupReport />}
            />
            <Route
              path="/manager/dashboard/checkup/report/week"
              element={<DashboardCheckupReportWeek />}
            />
            <Route
              path="/manager/dashboard/checkup/report/month"
              element={<ManagerMonthReport />}
            />
            <Route
              path="/manager/dashboard/checkup/share"
              element={<CheckupShareListPage />}
            />
            <Route
              path="/manager/dashboard/checkup/share/:id"
              element={<CheckupShareAnswerPage />}
            />

            <Route
              path="/manager/dashboard/checkup/users"
              element={<UsersManagement />}
            />

            <Route
              path="/manager/dashboard/payment"
              element={<ManagerPaymentManagement />}
            />
            <Route
              path="/manager/dashboard/payment/history"
              element={<ManagerPaymentHistory />}
            />
          </Route>
        </Route>

        <Route element={<RequireAuth />}>
          <Route
            element={
              <DashboardLayout
                type={TYPE_LEADER}
                items={SIDEBAR_ITEMS_LEADER}
              />
            }
          >
            <Route path="/leader/dashboard" element={<LeaderDashboard />} />
            <Route
              path="/leader/dashboard/checkup/share"
              element={<CheckupShareListPage />}
            />
            <Route
              path="/leader/dashboard/checkup/share/:id"
              element={<CheckupShareAnswerPage />}
            />
            <Route
              path="/leader/dashboard/checkup/report"
              element={<DashboardCheckupReport />}
            />
            <Route
              path="/leader/dashboard/checkup/report/month"
              element={<DashboardCheckupReportMonth />}
            />
            <Route
              path="/leader/dashboard/checkup/report/week"
              element={<DashboardCheckupReportWeek />}
            />
            <Route
              path="/leader/dashboard/groups"
              element={<GroupsManagement />}
            />
            <Route
              path="/leader/dashboard/users"
              element={<UsersManagement />}
            />
            <Route
              path="/leader/dashboard/withdraw"
              element={<WithdrawManagement />}
            />
          </Route>
          <Route element={<Layout type={TYPE_LEADER} />}>
            <Route
              path="/checkup/v2/leader-report"
              element={<LeaderReport />}
            />
          </Route>
          <Route element={<Layout type={TYPE_LEADER} />}>
            <Route path="/admin/report" element={<AdminReport />} />
          </Route>
        </Route>
      </Route>

      {/* <Route path="/recommend" element={<Exercise />} />
      <Route path="/recommend/:id" element={<Recommend />} />
      <Route path="/recommend/:id/:detailId" element={<RecommendDetail />} />
      <Route path="/recommend/review" element={<Review />} />
      <Route path="/unsubscribe" element={<Unsubscribe />} /> */}
      {/* <Route path="/relax" element={<RelaxingOne />} />
      <Route path="/map" element={<Map />} />
      <Route path="/typing" element={<Typing />} /> */}
    </Routes>
  );
}

export default App;
